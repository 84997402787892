import require from '@/utils/require';
import { network, auto } from '@/config/geteway';
// 国家
export const findCountry = function(data = {}) {
  return require({
    url: `${network}/${auto}/country/findCountry`,
    method: 'POST',
    data
  });
};
// 币种
export const findCurrencyInfo = function(data = {}) {
  return require({
    url: `${network}/${auto}/currencyinfo/findCurrencyInfo`,
    method: 'POST',
    data
  });
};
// 运输方式
export const findTransportWay = function(data = {}) {
  return require({
    url: `${network}/${auto}/transportway/findTransportWay`,
    method: 'POST',
    data
  });
};
// 目的仓库
export const findfabWarehouse = function(data = {}) {
  return require({
    url: `${network}/${auto}/fabwarehouse/findfabWarehouse`,
    method: 'POST',
    data
  });
};
export const findQuotationTypeBy = function(data = {}) {
  return require({
    url: `${network}/${auto}/quotationtype/findQuotationTypeBy`,
    method: 'POST',
    data
  });
};
export const lookEditOrderInfo = function(data = {}) {
  return require({
    url: `${network}/${auto}/orderinfo/lookEditOrderInfo`,
    method: 'POST',
    data
  });
};
