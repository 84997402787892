<template>
  <div class="Address">
    <div v-loading="loading" class="c-box">
      <div>
        <div class="menu2">
          <el-button :class="{'bu-bg':true,'act':searchData.types==1}" @click="findDeliveryAddressByPage(1)">提货地址</el-button>
          <el-button :class="{'bu-bg':true,'act':searchData.types==2}" style="margin-left:30px" @click="findDeliveryAddressByPage(2)">海外仓/收货地址</el-button>
          <div style="margin-top:20px">
            <el-button class="ebuton bhe32" type="primary" round @click="address=true">添加地址</el-button>
          </div>
        </div>
        <div v-if="!loading" class="content">
          <el-table
            class="freightRate-table center"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              v-if="searchData.types==1"
              prop="warehouseName"
              align="center"
              label="目的地仓库"
            />
            <el-table-column
              prop="contacts"
              align="center"
              label="联系人"
            />
            <el-table-column
              prop="phone"
              align="center"
              label="联系电话"
            />
            <el-table-column
              prop="address"
              align="center"
              label="收货地址"
            />
            <el-table-column
              prop="zipCode"
              align="center"
              label="邮编"
            />
          </el-table>
          <el-pagination
            class="fenye"
            background
            layout="prev, pager, next"
            :current-page="searchData.pageNum"
            :page-size="searchData.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <el-dialog :title="searchData.types==1?'添加提货地址':'添加收货地址'" :visible.sync="address" class="address" custom-class="w840" @close="address=false">
        <el-form :model="addData" :inline="true" label-width="auto">
          <el-form-item label="联系人">
            <el-input v-model="addData.contacts" />
          </el-form-item>
          <el-form-item label="国家">
            <el-select v-model="addData.countryName" @change="changeCountry">
              <el-option
                v-for="item in Country"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="城市">
            <el-input v-model="addData.cityName" />
          </el-form-item>
          <el-form-item label="邮编">
            <el-input v-model="addData.zipCode" />
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="addData.phone" />
          </el-form-item>
          <el-form-item label="省/州">
            <el-input v-model="addData.stateName" />
          </el-form-item>
          <template v-if="searchData.types !== 1">
            <el-form-item label="仓库代码">
              <el-input v-model="addData.warehouseCode" />
            </el-form-item>
            <el-form-item label="仓库名称">
              <el-input v-model="addData.warehouseName" />
            </el-form-item>
          </template>
          <el-form-item :label="searchData.types==1?'提货地址':'收货地址'">
            <el-input v-model="addData.address" type="textarea" :rows="3" />
          </el-form-item>
        </el-form>
        <p style="text-align:center;">
          <el-button class="ebuton" type="primary" round @click="saveDeliveryAddress">确定</el-button>
          <el-button class="nbuton" type="primary" round @click="closeModel">关闭</el-button>
        </p>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { findDeliveryAddressByPage, saveDeliveryAddress } from '@/api/goods.js';
import { findCountry } from '@/api/select';
export default {
  name: 'Address',
  props: {

  },
  data: function() {
    return {
      loading: false,
      address: false,
      // 搜索信息
      searchData: {
        types: this.$store.state.addType,
        pageNum: 1,
        pageSize: 10
      },
      // 表格信息
      tableData: [],
      total: 0,
      // 添加地址信息
      addData: {},
      Country: []
    };
  },
  computed: {
    // type:function(){
    //   const data=this.searchData
    //   if(data.types==1){
    //     return
    //   }
    // }
  },
  watch: {

  },
  created() {
    this.findDeliveryAddressByPage();
    this.init();
  },
  mounted() {

  },
  methods: {
    init() {
      findCountry().then(result => {
        if (result.status == 200) {
          this.Country = result.data.data;
        }
      });
    },
    changeCountry(val) {
      this.$set(this.addData, 'countryCode', val ? (this.Country.find(item => item.name === this.addData.countryName) || {}).code : null);
    },
    findDeliveryAddressByPage(type) { // 地址列表
      this.loading = true;
      if (type) {
        this.searchData.types = type;
        this.$store.dispatch('setAddType', type);
      }
      findDeliveryAddressByPage(this.searchData).then(result => {
        if (result.status == 200) {
          const data = result.data.data;
          this.tableData = data.list;
          this.total = data.total;
        }
        this.loading = false;
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
    saveDeliveryAddress() { // 添加地址
      this.addData.types = this.searchData.types;
      saveDeliveryAddress(this.addData).then(result => {
        if (result.status == 200) {
          this.$message({
            message: '添加成功',
            type: 'success'
          });
          this.closeModel();
        }
      }).catch(error => {
        console.log(error);
      });
    },
    closeModel() { // 关闭窗口清除数据
      this.addData = {};
      this.address = false;
      this.findDeliveryAddressByPage();
    },
    handleCurrentChange(val) { // 切换页数
      this.searchData.pageNum = val;
      this.findDeliveryAddressByPage();
    },
    goto(route) {
      this.$router.push({ path: route });
    }
  }
};

</script>

<style scoped>

.productWidth{width:840px;}
.ebuton{min-width:120px;background:#507EFF;margin:0 15px;}
.nbuton{min-width:120px;background:#EEEEEE;margin:0 15px;border-color:#EEEEEE;color:#999999}
.menu2{margin: 20px 0 20px;}
.bu-bg{color:#507EFF;border-color:#81A2FF;background:#EEF2FF}
.act{background: #507EFF;
    color: #fff;}
</style>
